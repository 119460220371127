@use 'functions/theme-builder.function' as theme-builder-fn;
@use 'mixins/theme-builder.mixin';

$theme-accent: #ffe923;
$error-color: #f44336;

$ak-dark-palette: theme-builder-fn.create-flat-palette($theme-accent, black);
$ak-dark-warn-palette: theme-builder-fn.create-flat-palette($error-color, white);
$ak-dark-theme: theme-builder-fn.create-theme(true, $ak-dark-palette, $ak-dark-warn-palette);

.material-theme-akelius-dark {
  color-scheme: dark;

  --background: #303030;
  --accent-color: rgba(255, 255, 255, 0.8);
  --font-standard-color: rgba(255, 255, 255, 0.8);
  --header-icon-color: rgba(255, 255, 255, 0.8);
  --line-color: rgba(255, 255, 255, 0.3);

  --slide-accent-color: rgba(255, 255, 255, 0.8);

  --icon-font-color: var(--header-icon-color);
  --mdc-theme-text-primary-on-background: var(--font-standard-color);
  --button-standard-background-color: rgba(66, 66, 66, 1);
  --button-standard-text-color: var(--font-standard-color);
  --button-standard-border-color: var(--font-standard-color);
  --font-link-hover-color: var(--font-standard-color);
  --ck-color-base-background: rgba(66, 66, 66, 1);
  --mat-table-header-headline-color: var(--font-standard-color);
  --table-row-hover: rgba(117, 117, 117, 1);
  --tooltip-container-color: rgba(117, 117, 117, 1);
  --primary-text-color: var(--font-standard-color);
  --mat-option-selected-state-label-text-color: black;

  --status-error-color: #f44336;

  @include theme-builder.override-theme-colors($ak-dark-theme);

  @include theme-builder.set-ripple-color($ak-dark-theme);
}
