@use '@angular/material' as mat;
@use 'sass:map';

@mixin apply-theme($theme) {
  @include mat.all-component-themes($theme);
}

@mixin override-theme-colors($theme) {
  @include mat.all-component-colors($theme);
}

@mixin apply-typography($typography) {
  @include mat.all-component-typographies($typography);
}

@mixin set-ripple-color($theme) {
  & {
    $foreground-base: map.get($theme, foreground, base);
    --ripple-color: #{$foreground-base};
  }
}
